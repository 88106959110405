import React, { memo } from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import Error404 from "../containers/Error404"

interface Props {}

const NotFound = memo((props: Props) => {
  return (
    <Layout title="404: Page not found">
      <SEO title="404: Page not found" />
      <Error404 />
    </Layout>
  )
})

export default NotFound
