import React, { memo } from "react"
import Head from "./head"
import DropdownFixedNavbar from "./Navbars/DropdownFixedNavbar"
import FooterBlack from "./Footers/FooterBlack/index"
import "../styles.css"
import { USER_TYPE } from "../configs/constant"

interface LayoutProps {
  title: string
  description?: string
  image?: { id: string; url: string }
  children: React.ReactNode
}

const Layout = memo((props: LayoutProps) => {
  return (
    <>
      <Head {...props} />
      <DropdownFixedNavbar />
      {props.children}
      <FooterBlack />
    </>
  )
})

export default Layout
